var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "wrap-container", attrs: { direction: "vertical" } },
    [
      _c("river-header", [
        _c("span", { staticClass: "flow-title" }, [
          _vm._v(_vm._s(_vm.formConfig.name)),
        ]),
      ]),
      _c("el-main", { staticClass: "wrap-main" }, [
        _c("div", { staticClass: "form-printer-container" }, [
          _c(
            "div",
            { staticClass: "form-printer-left" },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("lang_enter_keywords_to_filter") },
                model: {
                  value: _vm.filterText,
                  callback: function ($$v) {
                    _vm.filterText = $$v
                  },
                  expression: "filterText",
                },
              }),
              _c("el-tree", {
                ref: "tree",
                staticClass: "filter-tree",
                attrs: {
                  data: _vm.widgetTree,
                  "default-expand-all": "",
                  "node-key": "id",
                  "highlight-current": "",
                  "expand-on-click-node": false,
                  "filter-node-method": _vm.handleOutlinesTreeFilter,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-printer-main" },
            [_c("form-printer")],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }