





























import { Component, Vue, Watch, ProvideReactive } from 'vue-property-decorator';
import { getFormConfig } from '@/api/form-design/FormApi';
import { FormData, FormModel } from '@/models/form/FormModel';
import RiverHeader from '../layout/components/river-header/index.vue';
import FormPrinter from '@/components/form-design/printer/main.vue';
import StoreIndex from '@/store/StoreIndex';
import draggable from 'vuedraggable';

@Component({
  name: 'FormPrinterIndex',
  components: {
    draggable,
    RiverHeader,
    FormPrinter
  }
})
export default class Form extends Vue {
  formConfig: FormModel = FormData;
  showLeftAreaFlag: boolean = true;
  filterText: string = '';
  widgetTree: Record<string, any>[] = [];
  widgetTemplateList: any[] = [];

  @ProvideReactive()
  formId: any = null;

  created() {
    this.buildFormInfo();
  }

  // 构建表单信息及组件信息, 初始化表单页面
  buildFormInfo() {
    let formId = this.$route.query.formId || JSON.parse(sessionStorage.getItem('pFormId') as any);
    if (formId) {
      getFormConfig({ id: formId }).then((res: Record<string, any>) => {
        if (res.code == '1' && res.data) {
          if (res.data.container.components) {
            this.buildOutlinesTree(res.data.container.components);
          } else {
            res.data.container.components = [];
            this.widgetTree = [];
          }
          this.formConfig = res.data;
          StoreIndex.commit('SET_FORM_CONFIG', res.data);
          this.formId = formId;
        }
      });
    }
  }

  // 构建左侧大纲树
  buildOutlinesTree(components) {
    this.widgetTree = [];
    let arr: Record<string, any>[] = [];
    components.forEach((item, index) => {
      if (item.compType == 'row') {
        let obj: Record<string, any> = {
          id: item.id,
          compCode: item.compCode,
          label: this.$t(item.compName),
          compType: item.compType,
          children: []
        };
        item.properties.gridColumns.forEach((el, elIndex) => {
          el.components.forEach((child, childIndex) => {
            let childObj = {
              id: child.id,
              compCode: item.compCode,
              compType: child.compType,
              label: this.$t(child.compName)
            };
            obj.children.push(childObj);
          });
        });
        arr.push(obj);
      } else if (item.compType == 'table') {
        let obj: any = {
          id: item.id,
          compCode: item.compCode,
          label: this.$t(item.compName),
          compType: item.compType,
          children: []
        };
        item.properties.components.forEach((child, childIndex) => {
          let childObj = {
            id: child.id,
            compCode: item.compCode,
            compType: child.compType,
            label: this.$t(child.compName)
          };
          obj.children.push(childObj);
        });
        arr.push(obj);
      } else if (item.compType == 'card') {
        let obj: any = {
          id: item.id,
          compCode: item.compCode,
          label: this.$t(item.compName),
          compType: item.compType,
          children: []
        };
        item.properties.components.forEach((child, childIndex) => {
          let childObj = {
            id: child.id,
            compCode: item.compCode,
            compType: child.compType,
            label: this.$t(child.compName)
          };
          obj.children.push(childObj);
        });
        arr.push(obj);
      } else if (item.compType === 'html') {
        let obj = {
          id: item.id,
          compCode: item.compCode,
          label: this.$t(item.compName),
          compType: item.compType,
          node: item.node,
          dataSourceConfigs: item.dataSourceConfigs
        };
        arr.push(obj);
      } else {
        let obj = {
          id: item.id,
          compCode: item.compCode,
          label: this.$t(item.compName),
          compType: item.compType
        };
        arr.push(obj);
      }
    });
    let fatherObj = {
      id: '000001',
      code: null,
      compType: null,
      label: this.$t('lang_form'),
      children: arr
    };
    this.widgetTree.push(fatherObj);
  }

  // 树形过滤
  handleOutlinesTreeFilter(value, data) {
    if (!value) return true;
    return data.label.indexOf(value) !== -1;
  }

  // 监听 树形过滤
  @Watch('filterText')
  onValueChange(value: string) {
    (this.$refs.tree as any).filter(value);
  }
}
