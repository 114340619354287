




import { Component, Vue, Watch, InjectReactive, Prop } from 'vue-property-decorator';
import LuckyExcel from '../utils/luckyexcel/main';
@Component({
  name: 'FormPrinter',
  components: {}
})
export default class Designer extends Vue {
  mounted() {
    this.$nextTick(() => {
      let $: any = (window as any).$;
      let luckysheet: any = (window as any).luckysheet;
      $(function () {
        luckysheet.create({
          container: 'luckysheet', // 设定DOM容器的id
          lang: 'zh', // 设定表格语言
          // plugins: ['chart'], //不要用插件，会导致生命周期异常
          enableAddRow: false,
          enableAddBackTop: false,
          showinfobar: false,
          data: [
            {
              name: 'Cell', //工作表名称
              color: '', //工作表颜色
              index: 0, //工作表索引
              status: 1, //激活状态
              order: 0, //工作表的下标
              hide: 0, //是否隐藏

              defaultRowHeight: 19, //自定义行高
              defaultColWidth: 73, //自定义列宽
              celldata: [], //初始化使用的单元格数据
              config: {
                merge: {}, //合并单元格
                rowlen: {}, //表格行高
                columnlen: {}, //表格列宽
                rowhidden: {}, //隐藏行
                colhidden: {}, //隐藏列
                borderInfo: {}, //边框
                authority: {} //工作表保护
              },
              scrollLeft: 0, //左右滚动条位置
              scrollTop: 315, //上下滚动条位置
              luckysheet_select_save: [], //选中的区域
              calcChain: [], //公式链
              isPivotTable: false, //是否数据透视表
              pivotTable: {}, //数据透视表设置
              filter_select: {}, //筛选范围
              filter: null, //筛选配置
              luckysheet_alternateformat_save: [], //交替颜色
              luckysheet_alternateformat_save_modelCustom: [], //自定义交替颜色
              luckysheet_conditionformat_save: {}, //条件格式
              frozen: {}, //冻结行列配置
              chart: [], //图表配置
              zoomRatio: 1, // 缩放比例
              image: [], //图片
              showGridLines: 1, //是否显示网格线
              dataVerification: {} //数据验证配置
            }
          ],
          hook: {
            workbookCreateAfter: function (json) {
              $('#luckysheet-save').click(evt => {
                console.log('---tojson---', JSON.stringify(luckysheet.toJson()));
                console.log('---getAllSheets---', JSON.stringify(luckysheet.getAllSheets()));
                luckysheet.setSheetAdd();
              });
              $('#luckysheet-importExcel').on('change', function (evt) {
                var files = evt.target.files;
                if (files == null || files.length == 0) {
                  alert('No files wait for import');
                  return;
                }

                let name = files[0].name;
                let suffixArr = name.split('.'),
                  suffix = suffixArr[suffixArr.length - 1];
                if (suffix != 'xlsx') {
                  alert('Currently only supports the import of xlsx files');
                  return;
                }
                LuckyExcel.transformExcelToLucky(files[0], (exportJson: any, luckysheetfile: any) => {
                  if (exportJson.sheets == null || exportJson.sheets.length == 0) {
                    alert('Failed to read the content of the excel file, currently does not support xls files!');
                    return;
                  }

                  let oldSheets = luckysheet.getluckysheetfile();
                  exportJson.sheets.forEach(item => {
                    luckysheet.setSheetAdd({ sheetObject: item, order: parseInt(item.order) + 1 });
                  });
                  oldSheets.forEach(oldItem => {
                    luckysheet.setSheetDelete({ order: parseInt(oldItem.order) });
                  });
                });
              });
            }
          }
        });
      });
    });
  }
}
